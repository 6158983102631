.swiper-a {
    margin-top: 100px;
    width: 100%;
    padding: 30px;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
.content-s{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.s-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* margin-top: -30px; */
}



.swiper-image {
    width: 100%;
    height: 500px;
    object-fit: cover;
}

@media only screen and (max-width: 768px){
    .swiper-image{
        margin-top: 100px;
        width: 100%;
        height: 250px;
        object-fit: cover;
    }
    .swiper-a{
        height: 300px;
        margin-top: 100px;
    }

}