.swiper {
    width: 90%;
    margin-top: 60px;
    min-height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
.content-s{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
}
.s-head{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    
}