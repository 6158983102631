/* Results.css */

/* Add a background color to the entire results page */
.results-container {
  background-color: #f7f7f7; /* Updated background color */
  /* Other existing styles */
}
.toppers-section {
  margin: 20px auto;
  width: 70%;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
}
.table-header,
.table-row {
  display: flex;
}
.table-column {
  flex: 1;
  padding: 8px;
  border-bottom: 2px solid #ccc;
}

.table-header {
  font-weight: bold;
}
.toppers-table {
  display: flex;
  flex-direction: column;
}
/* Updated styles for sections */
.records-section,

.students-marks-section {
  margin: 20px auto;
  width: 70%;
  text-align: center;
  border: 2px solid #ccc;
  border-radius: 8px;
  padding: 20px;
  background-color: #fff; /* Background color for sections */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Box shadow effect */
}

/* Updated styles for section headings */
.section-heading {
  font-weight: bold;
  text-align: center;
  font-size: 1.5em;
}

/* Updated styles for table sections */
.records-data,

.students-marks-table {
  border-collapse: collapse;
  width: 100%;
}

/* Updated styles for table headers and cells */
.records-data th,
.records-data td,


.students-marks-table th,
.students-marks-table td {
  border: 2px solid #ccc; /* Thick border for table cells */
  padding: 8px; /* Adjust cell padding */
}

/* Compress table content */
.records-data th,

.students-marks-table th {
  text-align: center;
  font-weight: bold;
}

.records-data td,

.students-marks-table td {
  text-align: center;
  font-weight: normal;
}

/* Adjust table header background and text color */
.records-data th,

.students-marks-table th {
  background-color: white; /* Light gray background */
  color: #000;
}

/* Updated loader styles */
.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px; /* Adjust height as needed */
}

.loader {
  border: 4px solid #f3f3f3; /* Light grey border */
  border-top: 4px solid #3498db; /* Blue border for animation */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite; /* Animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Updated card-like component styles */
.record-cards-container {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.record-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow effect */
  padding: 20px;
  margin-bottom: 20px;
  width: 40%; /* Adjust the width as needed */
  display: inline-block; /* Display cards side by side */
  text-align: center;
}

.record-card h3 {
  font-size: 2em; /* Increase the font size as needed */
  margin-bottom: 10px;
}

.record-card p {
  font-weight: bold;
  font-size: 1.2em; /* Increase the font size as needed */
}
@media only screen and (max-width: 768px){
  .records-section{
    width: 90%;
  }
  .record-card{
    width: 100%;
  }
  .toppers-section{
    width: 90%;
  }
}