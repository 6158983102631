/* HomePage.css */

/* Style for the main content container */

.school-title-header{
  margin-left: 600px;
}
.main-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}

/* Style for each card-like section */
.content > div {
  width: 90%;
  margin-bottom: 20px;
  padding: 20px;
  background-color:  #fff;;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.content {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; /* Add padding as needed */
}
/* Style for headings within the card */
.content > div > h2 {
  text-align: center;
  color: #333;
}

/* Style for paragraphs within the card */
.content > div > p {
  color: #555;
  line-height: 1.6;
}


.HomeSection1 .vision-mission,
.HomeSection1 .patron-about-patron,
.HomeSection1 .founder-about-founder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  margin-top: 50px;

}

/* Style for individual sections within side-by-side layout */
.HomeSection1 .vision,
.HomeSection1 .mission,
.HomeSection1 .patron,
.HomeSection1 .about-patron,
.HomeSection1 .founder,
.HomeSection1 .about-founder {
  width: 48%; /* Adjust width as needed */
}
.slider-phone{
  display: none;
}
/* Additional styling for responsiveness or other specific needs */
@media (max-width: 1400px) {
  .HomeSection1 .vision-mission,
  .HomeSection1 .patron-about-patron,
  .HomeSection1 .founder-about-founder {
    flex-direction: column;
    margin-bottom: 10px;
  }

  /* Adjust individual section width for smaller screens if needed */
  .HomeSection1 .vision,
  .HomeSection1 .mission,
  .HomeSection1 .patron,
  .HomeSection1 .about-patron,
  .HomeSection1 .founder,
  .HomeSection1 .about-founder {
    width: 100%;
  }
}



.HomeSection1 h2 {
  font-family: 'Your Custom Heading Font', sans-serif; /* Change 'Your Custom Heading Font' to your preferred font */
  font-size: 24px; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  color: #333; /* Change color as needed */
  margin-bottom: 10px; /* Adjust margin as needed */
}

.HomeSection1 h4 {
  font-family: 'Your Custom Heading Font', sans-serif; /* Change 'Your Custom Heading Font' to your preferred font */
  font-size: 18px; /* Adjust font size as needed */
  font-weight: bold; /* Adjust font weight as needed */
  color: #555; /* Change color as needed */
  margin-bottom: 8px; /* Adjust margin as needed */
}

/* Custom styles for paragraphs */
.HomeSection1 p {
  font-family: Lato, sans-serif; /* Change 'Your Custom Paragraph Font' to your preferred font */
  font-size: 16px; /* Adjust font size as needed */
  line-height: 1.6; /* Adjust line height as needed */
  color: #777; /* Change color as needed */
  margin-bottom: 15px; /* Adjust margin as needed */
}


/* Style for the new section */
.our-principles {
  text-align: center;
}

/* Style for the section heading */
.section-heading {
  
  font-weight: bold;
  margin-bottom: 20px;
}

/* Style for the container of principles' images and labels */
.principles-images {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap; /* Ensure images wrap to next line if they exceed width */
  max-width: 1200%;
}

/* Style for each principle */
.principle {
  margin: 10px;
  padding: 10px;
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Box shadow effect */
}

/* Style for principle image */
.principle img {
  width: 150px; /* Adjust width as needed */
  height: 150px; /* Adjust height as needed */
  border-radius: 10px;
  margin-bottom: 10px;
}

/* Style for principle label */
.principle p {
  font-size: 20px;
  color: #333;
  flex-basis: calc(50% - 20px); /* Adjust flex basis to 50% minus margin */
  margin: 10px; 
}
.slider-mobile{
  margin-top: 30px;
}
@media only screen and (max-width: 768px){
  .slider-phone{
    display: inline;
  }
  .slider-mobile{
    display: none;
  }
}